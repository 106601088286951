<script>
/**
 * Widget Component
 */

import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import moment from 'moment';

export default {
  props : ['telecallers'],
  components : {
    Multiselect,
    DatePicker
  },
  data() {
    return {
        filters : {
            // verified_with_mci_status : [],
            daterange : null,
            telecallers : [],
        },
        // statuses : [
        //     {
        //       key : 0,
        //       value : 'Registered'
        //     },
        //     {
        //       key : 1,
        //       value : 'Verified'
        //     },
        //     {
        //       key : 2,
        //       value : '(Auto) Not Valid'
        //     },
        //     {
        //       key : 3,
        //       value : 'Document Under Processing'
        //     },
        //     {
        //       key : 4,
        //       value : 'Document Pending'
        //     },
        //     {
        //       key : 5,
        //       value : '(Admin) Not Valid'
        //     },
        // ],
    };
  },
  methods : {
    getFormatedDate(date){
        try {
          return moment(date).format('yyyy-MM-DD');
        }catch (err) {
          console.log(err);
          return null;
        }
    },
    getParams(params){
      let statuses = this.filters.verified_with_mci_status ? this.filters.verified_with_mci_status.map(obj => obj.key) : {};
      if(statuses && statuses.length > 0){
        params.verified_with_mci_status = statuses;
      }
      let from_date = this.filters.daterange && this.filters.daterange.length > 0 ? this.getFormatedDate(this.filters.daterange[0]) : null;
      if(from_date){
        params.from_date = from_date;
      }
      let to_date = this.filters.daterange && this.filters.daterange.length > 0 && this.filters.daterange[1] ? this.getFormatedDate(this.filters.daterange[1]) : null;
      if(to_date){
        params.to_date = to_date;
      }
      if(this.filters.telecallers.length > 0){
        params.telecaller_ids = this.filters.telecallers.map(t => t.id);
      }
      this.params = {};
      this.params = params;
      return params;
    },
  },
  mounted() {
  },
  watch :{
    filters : {
      deep : true,
      handler() {
        let params = this.getParams({});
        this.$emit('filters',params);
      }
    },
  }
};
</script>
<template>
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label>Team</label>
        <multiselect v-model="filters.telecallers" :show-labels="false" :close-on-select="false" :options="telecallers" track-by="id" label="username" :multiple="true"></multiselect>
      </div>
    </div>
    <div class="col-md-4">
        <div class="form-group">
            <label>Date Range</label>
            <date-picker v-model="filters.daterange" :disabled-date="(date) => date >= new Date()" range append-to-body lang="en" confirm></date-picker>
        </div>
    </div>
  </div>
</template> 