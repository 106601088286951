<script>
import appConfig from "@/app.config";
import {fieldMrReferralSummary} from "../../../path";
import JsonExcel from "vue-json-excel";

export default {
    props : ['filters','telecallers_data'],
    name : 'referralSummary',
    data(){
        return {
            data : [],
            filter : null,
            filter_data : null,
            statuses: [
              {
                "key" : "total_referrers",
                "display" : "Referrers",
                "value" : 0,
              },
              {
                "key" : "total_referrals",
                "display" : "Referrals",
                "value" : 0,
              },
              {
                "key" : "total_prime",
                "display" : "Prime",
                "value" : 0
              },
              {
                "key" : "total_verified",
                "display" : "Verified",
                "value" : 0,
                "color": 'background: #bfecdf'
              },
              {
                "key" : "total_yet_to_verify",
                "display" : "Pending",
                "value" : 0,
                "color": 'background: #cce5ff'
              },
              {
                "key" : "processing_count",
                "display" : "Processing",
                "value" : 0,
                "color": 'background: #feebc4'
              },
              {
                "key" : "total_unverified",
                "display" : "Rejected",
                "value" : 0,
                "color": 'background: #ffc9d2'
              },
              {
                "key" : "total_duplicate_users",
                "display" : "Same Device Usage",
                "value" : 0,
                "color": 'background: #ffc9d2'
              },
              {
                "key" : "total_google_id_duplicate_users",
                "display" : "Same Google ID Usage",
                "value" : 0,
                "color": 'background: #ffc9d2'
              }
            ],
            field_mr_referral : {
              data : [],
              currentPage : 1,
              key : 0,
              perPage : 10,
              pageOptions : [10,20,50,100],
              totalRows : 1,
              sortBy: "total_count",
              sortDesc: true,
              filter: null,
              filterOn: [],
              fields : [
                {key: "username", sortable: true ,label : 'Name'},
                {key: "referral_code", sortable: true, label: 'Referral Code'},
                {key: "duplicate_users", sortable: true, label: 'Same Device Usage'},
                {key: "duplicate_gid_users", sortable: true, label: 'Same Google Id Usage'},
                {key: "total_count", sortable: true ,label : 'Referrals'},
                {key: "prime_count", sortable: true ,label : 'Total Prime'},
                {key: "verified_count", sortable: true ,label : 'Verified'},
                {key: "verified_with_document_count", sortable: true ,label : 'Verified (With Document)'},
                {key: "verified_without_document_count", sortable: true ,label : 'Verified (Without Document)'},
                {key: "pending_document_count", sortable: true ,label : 'Pending Documents'},
                {key: "not_action_taken_count", sortable: true ,label : 'No Action Taken Documents'},
                {key: "awaiting_document_count", sortable: true ,label : 'Awaiting Documents'},
                {key: "other_registrants", sortable: true ,label : 'Other Registrants'},
                {key: "under_processing_count", sortable: true ,label : 'Under Processing'},
                {key: "call_to_verify_not_verified_yet_count", sortable: true ,label : 'Call To Verify (Not Yet Verified)'},
                {key: "under_processing_call_to_verify_count", sortable: true ,label : 'Under Process (Call to Verify)'},
                {key: "under_processing_document_to_verify_count", sortable: true ,label : 'Under Process (Document to Verify)'},
                {key: "rejected_by_admin", sortable: true ,label : 'Rejected By Admin'},
              ],
            },
            d2d_referral : {
              data : [],
              currentPage : 1,
              key : 0,
              perPage : 5,
              pageOptions : [5,10,15,20],
              totalRows : 1,
              fields : [
                  {key: "city", sortable: true ,label : 'City'},
                  {key: "total_count", sortable: true ,label : 'Total Doctors'},
                  {key: "prime_count", sortable: true, label: 'Total Prime Doctors'}
              ],
            },
            mr_city_coverage : {
              data : [],
              currentPage : 1,
              key : 0,
              perPage : 5,
              pageOptions : [5,10,15,20],
              totalRows : 1,
              fields : [
                {key: "username", sortable: true ,label : 'Name'},
                {key: "city", sortable: true ,label : 'City'},
                {key: "total_count", sortable: true ,label : 'Referrals'},
              ],
            },
            mr_state_coverage : {
              data : [],
              currentPage : 1,
              key : 0,
              perPage : 5,
              pageOptions : [5,10,15,20],
              totalRows : 1,
              fields : [
                {key: "username", sortable: true ,label : 'Name'},
                {key: "state", sortable: true ,label : 'State'},
                {key: "total_count", sortable: true ,label : 'Referrals'},
              ],
            },
            mr_member_type : {
              data : [],
              currentPage : 1,
              key : 0,
              perPage : 5,
              pageOptions : [5,10,15,20],
              totalRows : 1,
              fields : [
                {key: "username", sortable: true ,label : 'Agent Name'},
                {key: "doctor", sortable: true ,label : 'Doctor'},
                {key: "ayush_doctor", sortable: true ,label : 'AYUSH Doctor'},
                {key: "pg_student", sortable: true ,label : 'PG Student'},
                {key: "ug_student", sortable: true ,label : 'UG Student'},
                {key: "others", sortable: true ,label : 'Others'},
              ],
            }
        }
    },
    mounted(){
      // let user = this.$store.state.authfack.user;
      // if(!['AnalyticsAreaManager','AnalyticsRegionalManager'].includes(user.role)) {
      //   this.getFieldMr();
      // }
    },
    watch : {
      telecallers_data : {
        deep : true,
        handler() {
          this.getFieldMr();
        }
      },
      filters : {
        deep : true,
        handler(){
          this.getFieldMr();
        }
      }
    },
    methods : {
      async getFieldMr(){
          try{
              let params = Object.keys(this.filters).length > 0 ? this.filters : {};
              if(params.telecaller_ids != undefined){
                params.telecaller_ref_codes = this.telecallers_data.filter(f => params.telecaller_ids.includes(f.id)).map(m => m.referral_code);
              }
              let user = this.$store.state.authfack.user;
              if(['AnalyticsAreaManager','AnalyticsRegionalManager'].includes(user.role)){
                if(this.filters.telecaller_ids == undefined){
                  params.telecaller_ref_codes = this.telecallers_data.map(m => m.referral_code);
                  // params.telecaller_ids = this.telecallers_data.map(t => t.id);
                }
              }
            delete params.telecaller_ids;
            const res = await this.$http.get(appConfig.api_base_url + fieldMrReferralSummary,
                { params : params}
              );
              if(res.data){
                this.data = res.data.summary;
                this.field_mr_referral.data = this.filedMrReferral(this.data);
                this.d2d_referral.data = this.d2dReferral(this.data);
                this.mr_city_coverage.data = this.mrCityCoverage(this.data);
                this.mrStateCoverage(this.data);
                if(user.role == 'AnalyticsSuperAdmin'){
                  let duplicate_index = this.statuses.findIndex(f => f.key == 'total_duplicate_users');
                  if (duplicate_index != -1) {
                    this.statuses[duplicate_index].value = res.data.duplicate_device_token_count.reduce((a,b) => {return a+b.total_count}, 0);
                  }
                  let duplicate_gid_index = this.statuses.findIndex(f => f.key == 'total_google_id_duplicate_users');
                  if(duplicate_gid_index != -1){
                    this.statuses[duplicate_gid_index].value = res.data.duplicate_google_id_token.reduce((a,b) => {return a+b.total_count}, 0);
                  }
                }
              }
              let data = this.data;
              let ref_codes = this.telecallers_data.map(f => f.referral_code.toUpperCase());
              data = data.filter(f => ref_codes.includes(f.referred_by.toUpperCase()));
              this.statuses[0].value = this.telecallers_data.filter(f => [...new Set(data.map((item) => (item.referred_by).toUpperCase()))].includes((f.referral_code).toUpperCase())).length;
              this.statuses[1].value = data.reduce((a,c) => {
                return a + c.total_count;
              },0);

              this.statuses[2].value = data.reduce((a,c) => {
                return a + c.prime_count;
              },0);

              this.statuses[3].value = data.reduce((a,c) => {
                return a + c.verified_count;
              },0);

              this.statuses[4].value = parseInt(data.reduce((a,c) => {
                return a + (c.awaiting_document_count + c.other_registrants + c.not_action_taken_count);
              },0));

              this.statuses[5].value = parseInt(data.reduce((a,c) => {
                return a + (c.call_to_verify_not_verified_yet_count + c.under_processing_call_to_verify_count + c.under_processing_document_to_verify_count);
              },0));

              this.statuses[6].value = parseInt(data.reduce((a,c) => {
                return a + c.rejected_by_admin;
              },0));

              const compressedData = this.data.reduce((a, c) => {
                a = a.filter(f => f.referral_code != null && f.referral_code != '');
                const dateIndex = a.findIndex(
                  (o) =>
                    (o.referral_code).toUpperCase() === (c.referred_by).toUpperCase() &&
                    (c.referred_by != null || c.referred_by != "null" || c.referred_by != "")
                );
                if (dateIndex !== -1) {
                  a[dateIndex].total_count += parseInt(c.total_count);
                  a[dateIndex].prime_count += parseInt(c.prime_count);
                  a[dateIndex].verified_count += parseInt(c.verified_count);
                  a[dateIndex].verified_with_document_count += parseInt(c.verified_with_document_count);
                  a[dateIndex].verified_without_document_count += parseInt(c.verified_without_document_count);
                  a[dateIndex].call_to_verify_not_verified_yet_count += parseInt(c.call_to_verify_not_verified_yet_count);
                  a[dateIndex].awaiting_document_count += parseInt(c.awaiting_document_count);
                  a[dateIndex].not_action_taken_count += parseInt(c.not_action_taken_count);
                  a[dateIndex].under_processing_call_to_verify_count += parseInt(c.under_processing_call_to_verify_count);
                  a[dateIndex].under_processing_document_to_verify_count += parseInt(c.under_processing_document_to_verify_count);
                  a[dateIndex].other_registrants += parseInt(c.other_registrants);
                  a[dateIndex].rejected_by_admin += parseInt(c.rejected_by_admin);
                  a[dateIndex].under_processing_count = (a[dateIndex].call_to_verify_not_verified_yet_count + a[dateIndex].under_processing_call_to_verify_count + a[dateIndex].under_processing_document_to_verify_count);
                  a[dateIndex].pending_document_count = (a[dateIndex].awaiting_document_count + a[dateIndex].other_registrants + a[dateIndex].not_action_taken_count);
                } else {
                  let telecaller = this.telecallers_data.filter(t => (t.referral_code).toUpperCase() == (c.referred_by).toUpperCase());
                  if(telecaller.length > 0){
                    a.push({
                      username: telecaller.length > 0 ? telecaller[0].username : '',
                      referral_code: c.referred_by,
                      duplicate_users: user.role == 'AnalyticsSuperAdmin' ? res.data.duplicate_device_token_count.filter(f => f.referred_by == c.referred_by).reduce((a,b) => {return a+b.total_count}, 0) : 0,
                      duplicate_gid_users: user.role == 'AnalyticsSuperAdmin' ? res.data.duplicate_google_id_token.filter(f => f.referred_by == c.referred_by).reduce((a,b) => {return a+b.total_count}, 0) : 0,
                      total_count: c.total_count,
                      prime_count: c.prime_count,
                      verified_with_document_count: c.verified_with_document_count,
                      verified_count: c.verified_count,
                      verified_without_document_count: c.verified_without_document_count,
                      call_to_verify_not_verified_yet_count: c.call_to_verify_not_verified_yet_count,
                      pending_document_count: (c.awaiting_document_count + c.other_registrants + c.not_action_taken_count),
                      not_action_taken_count: c.not_action_taken_count,
                      awaiting_document_count: c.awaiting_document_count,
                      under_processing_call_to_verify_count: c.under_processing_call_to_verify_count,
                      under_processing_document_to_verify_count: c.under_processing_document_to_verify_count,
                      other_registrants: c.other_registrants,
                      rejected_by_admin: c.rejected_by_admin,
                      under_processing_count : c.call_to_verify_not_verified_yet_count + c.under_processing_call_to_verify_count + c.under_processing_document_to_verify_count,
                      _cellVariants: { duplicate_users: 'danger', duplicate_gid_users: 'danger', verified_count: 'success', rejected_by_admin: 'danger', pending_document_count: 'info', under_processing_count: 'warning'}
                    });
                  }
                }
                return a;
              }, []);
              compressedData.sort((a, b) => {
                if (a.total_count > b.total_count) {
                  return -1;
                }
                if (
                  a.total_count > b.total_count
                ) {
                  return 1;
                }
                return 0;
              });
              this.field_mr_referral.data  = compressedData;
              const memberTypeCompressedData = [...new Set(this.data.map(d => d.referred_by))].reduce((a, c) => {
                  let telecaller = this.telecallers_data.filter(t => (t.referral_code).toUpperCase() == (c).toUpperCase());
                  if(telecaller.length > 0){
                    a.push({
                      username: telecaller.length > 0 ? telecaller[0].username : '',
                      doctor : this.data.filter(f => f.member_type == 'doctor' && f.referred_by == telecaller[0].referral_code).map(a => {return a.total_count}).reduce((a,b) => {return a+b}, 0),
                      ayush_doctor : this.data.filter(f => f.member_type == 'ayush_doctor' && f.referred_by == telecaller[0].referral_code).map(a => {return a.total_count}).reduce((a,b) => {return a+b}, 0),
                      ug_student : this.data.filter(f => f.member_type == 'ug_student' && f.referred_by == telecaller[0].referral_code).map(a => {return a.total_count}).reduce((a,b) => {return a+b}, 0),
                      pg_student : this.data.filter(f => f.member_type == 'pg_student' && f.referred_by == telecaller[0].referral_code).map(a => {return a.total_count}).reduce((a,b) => {return a+b}, 0),
                      others : this.data.filter(f => f.member_type != 'doctor' && f.member_type != 'ayush_doctor' && f.member_type != 'ug_student' && f.member_type != 'pg_student'  && f.referred_by == telecaller[0].referral_code).map(a => {return a.total_count}).reduce((a,b) => {return a+b}, 0),
                    });
                  }
                return a;
              }, []);
        
              this.mr_member_type.data  = memberTypeCompressedData;
              this.mr_member_type.totalRows = memberTypeCompressedData.length;
              this.totalRowsData = this.data.length;
              this.key += 1;
          }catch(err) {
              console.log(err);
          }
      },
      filedMrReferral(v){
        let res = v.filter(f => f.referred_by != null && f.referred_by != '');
        res.forEach((r,ri) => {
          let telecaller = this.telecallers_data.filter(f => (f.referral_code).toUpperCase() == (r.referred_by).toUpperCase());
          if(telecaller.length > 0){
            res[ri].username = telecaller[0].username
          }else{
            delete res[ri];
          }
        });
        this.field_mr_referral.totalRows = res.length
        return res;
      },
      d2dReferral(v){
        let res = v.filter(f => f.referred_by != null && f.referred_by != '');
        res.forEach((r,ri) => {
          let telecaller = this.telecallers_data.filter(f => (f.referral_code).toUpperCase() == (r.referred_by).toUpperCase());
          if(telecaller.length > 0){
            res[ri].username = telecaller[0].username
          }else{
            delete res[ri];
          }
        });
        this.d2d_referral.totalRows = res.length;
        return res;
      },
      mrCityCoverage(v){
        let res = v.filter(f => f.referred_by != null && f.referred_by != '');
        res.forEach((r,ri) => {
          let telecaller = this.telecallers_data.filter(f => (f.referral_code).toUpperCase() == (r.referred_by).toUpperCase());
          if(telecaller.length > 0){
            res[ri].username = telecaller[0].username
          }else{
            delete res[ri];
          }
        });
        this.mr_city_coverage.totalRows = res.length;
        
        return res;
      },
      mrStateCoverage(v){
        let res = v.filter(f => f.referred_by != null && f.referred_by != '');
        res.forEach((r,ri) => {
          let telecaller = this.telecallers_data.filter(f => (f.referral_code).toUpperCase() == (r.referred_by).toUpperCase());
          if(telecaller.length > 0){
            res[ri].username = telecaller[0].username
          }else{
            delete res[ri];
          }
        });
        let mr_state_coverage = [];
        res.forEach(f => {
          let index = mr_state_coverage.findIndex(m => m.state == f.state);
          if(index == -1){
            mr_state_coverage.push({
              username : f.username,
              state : f.state,
              total_count : f.total_count
            });
          }else{
            mr_state_coverage[index].total_count += f.total_count;
          }
        });
        this.mr_state_coverage.data = mr_state_coverage;
        this.mr_state_coverage.totalRows = mr_state_coverage.length;

        return res;
      }
    },
  components : {
    JsonExcel
  }
};
</script>

<template>
  <div>
    <div class="statues-grid">
      <div class="" v-for="(s,si) in ($store.state.authfack.user.role == 'AnalyticsSuperAdmin' ? statuses : statuses.filter(f => (!['total_duplicate_users','total_google_id_duplicate_users'].includes(f.key))))" :key="si">
        <div class="card" :style="s.color">
          <div class="card-body p-2">
            <h4 class="card-title">{{s.display}}</h4>
            <h3>{{s.value}}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Referral Leaderboard</h4>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                 <b-form-select v-model="field_mr_referral.perPage" size="sm" :options="field_mr_referral.pageOptions"></b-form-select>&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center mr-5">
                  Search:
                  <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2"></b-form-input>
                  <!-- <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2"></b-form-input> -->
                </label>
                <JsonExcel
                    class="btn p-0"
                    :data="field_mr_referral.data"
                    name="referral_data.csv"
                    type="csv">
                  <i class="mdi mdi-download"></i>
                  Download
                </JsonExcel>
              </div>
            </div>
          
            <!-- End search -->
          </div>
          <div class="table-responsive mb-0">
            <b-table
                    :key="field_mr_referral.key"
                    :items="field_mr_referral.data"
                    :fields="$store.state.authfack.user.role == 'AnalyticsSuperAdmin' ? field_mr_referral.fields : field_mr_referral.fields.filter(f => f.key != 'duplicate_users')"
                    responsive="sm"
                    :per-page="field_mr_referral.perPage"
                    :filter="filter"
                    :current-page="field_mr_referral.currentPage"
                >
                <!-- <b-table
                    :key="field_mr_referral.key"
                    :items="field_mr_referral.data"
                    :fields="field_mr_referral.fields"
                    responsive="sm"
                    :per-page="10"
                    :current-page="field_mr_referral.currentPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                > -->
<!--                  <template v-slot:cell(verified_count)="row" class="bg-success">-->
<!--                    <span style="color: #fff;">-->
<!--                      {{row.value}}-->
<!--                    </span>-->
<!--                  </template>-->
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination :key="field_mr_referral.key" v-model="field_mr_referral.currentPage" :total-rows="field_mr_referral.totalRows" :per-page="field_mr_referral.perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   <div class="row"> 
<!--    <div class="col-6">-->
<!--      <div class="card">-->
<!--        <div class="card-body">-->
<!--          <h4 class="card-title">D2D Referred/ Organic</h4>-->
<!--          <div class="row mt-4">-->
<!--            <div class="col-sm-12 col-md-6">-->
<!--              <div id="tickets-table_length" class="dataTables_length">-->
<!--                <label class="d-inline-flex align-items-center">-->
<!--                  Show-->
<!--                <b-form-select v-model="d2d_referral.perPage" size="sm" :options="d2d_referral.pageOptions"></b-form-select>&nbsp;entries-->
<!--                </label>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash; Search &ndash;&gt;-->
<!--          -->
<!--            &lt;!&ndash; End search &ndash;&gt;-->
<!--          </div>-->
<!--          <div class="table-responsive mb-0">-->
<!--                <b-table-->
<!--                    :key="d2d_referral.key"-->
<!--                    :items="d2d_referral.data"-->
<!--                    :fields="d2d_referral.fields"-->
<!--                    responsive="sm"-->
<!--                    :per-page="d2d_referral.perPage"-->
<!--                    :current-page="d2d_referral.currentPage"-->
<!--                >-->
<!--                </b-table>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="col">-->
<!--                  <div class="dataTables_paginate paging_simple_numbers float-right">-->
<!--                    <ul class="pagination pagination-rounded mb-0">-->
<!--                      &lt;!&ndash; pagination &ndash;&gt;-->
<!--                      <b-pagination :key="d2d_referral.key" v-model="d2d_referral.currentPage" :total-rows="d2d_referral.totalRows" :per-page="d2d_referral.perPage"></b-pagination>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">State Coverage</h4>
          <div class="float-right">
            <JsonExcel
                class="btn p-0"
                :data="mr_state_coverage.data"
                name="mr_state_coverage_data.csv"
                type="csv">
              <i class="mdi mdi-download"></i>
              Download
            </JsonExcel>
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                <b-form-select v-model="mr_state_coverage.perPage" size="sm" :options="mr_state_coverage.pageOptions"></b-form-select>&nbsp;entries
                </label>
              </div>
            </div>
<!--            <div class="col-md-6">-->
<!--              Search :<b-form-input v-model="filter_data" type="search" class="form-control form-control-sm ml-2"></b-form-input>-->
<!--            </div>-->

            <!-- Search -->
          
            <!-- End search -->
          </div>
          <div class="table-responsive mb-0">
                <b-table
                    :key="mr_state_coverage.key"
                    :items="mr_state_coverage.data"
                    :fields="mr_state_coverage.fields"
                    responsive="sm"
                    :per-page="mr_state_coverage.perPage"
                    :current-page="mr_state_coverage.currentPage"
                    :filter="filter_data"
                >
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination :key="mr_state_coverage.key" v-model="mr_state_coverage.currentPage" :total-rows="mr_state_coverage.totalRows" :per-page="mr_state_coverage.perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
     <div class="col-6">
       <div class="card">
         <div class="card-body">
           <h4 class="card-title">City Coverage</h4>
           <div class="float-right">
             <JsonExcel
                 class="btn p-0"
                 :data="mr_city_coverage.data"
                 name="mr_city_coverage_data.csv"
                 type="csv">
               <i class="mdi mdi-download"></i>
               Download
             </JsonExcel>
           </div>
           <div class="row mt-4">
             <div class="col-sm-12 col-md-6">
               <div id="tickets-table_length" class="dataTables_length">
                 <label class="d-inline-flex align-items-center">
                   Show
                   <b-form-select v-model="mr_city_coverage.perPage" size="sm" :options="mr_city_coverage.pageOptions"></b-form-select>&nbsp;entries
                 </label>
               </div>
             </div>
             <!--            <div class="col-md-6">-->
             <!--              Search :<b-form-input v-model="filter_data" type="search" class="form-control form-control-sm ml-2"></b-form-input>-->
             <!--            </div>-->

             <!-- Search -->

             <!-- End search -->
           </div>
           <div class="table-responsive mb-0">
             <b-table
                 :key="mr_city_coverage.key"
                 :items="mr_city_coverage.data"
                 :fields="mr_city_coverage.fields"
                 responsive="sm"
                 :per-page="mr_city_coverage.perPage"
                 :current-page="mr_city_coverage.currentPage"
                 :filter="filter_data"
             >
             </b-table>
           </div>
           <div class="row">
             <div class="col">
               <div class="dataTables_paginate paging_simple_numbers float-right">
                 <ul class="pagination pagination-rounded mb-0">
                   <!-- pagination -->
                   <b-pagination :key="mr_city_coverage.key" v-model="mr_city_coverage.currentPage" :total-rows="mr_city_coverage.totalRows" :per-page="mr_city_coverage.perPage"></b-pagination>
                 </ul>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
     <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Member Type</h4>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                <b-form-select v-model="mr_member_type.perPage" size="sm" :options="mr_member_type.pageOptions"></b-form-select>&nbsp;entries
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <b-table
                :key="mr_member_type.key"
                :items="mr_member_type.data"
                :fields="mr_member_type.fields"
                responsive="sm"
                :per-page="mr_member_type.perPage"
                :current-page="mr_member_type.currentPage"
                :filter="filter_data"
            >
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <b-pagination :key="mr_member_type.key" v-model="mr_member_type.currentPage" :total-rows="mr_member_type.totalRows" :per-page="mr_member_type.perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   </div> 
  </div>
   
  
</template>

<style scoped>
.statues-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 10px;
}

</style>