<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Filters @filters="getFilters" :telecallers="telecallers" />
    <referralSummary :filters="data_filters" :telecallers_data="telecallers" />
    <!-- <AnalyticsStatesCities ></AnalyticsStatesCities> -->
    <!-- <div class="card">
      <div class="card-body">
        <div class="row">
            <div class="col-md-5">
              <Verified></Verified>
            </div>
            <div class="col-md-5">
              <NotVerified></NotVerified>
            </div>
            <div class="col-md-2">
              <TotalDoctors></TotalDoctors>
            </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ReferralsBy :filters="data_filters"></ReferralsBy>
      </div>
      <div class="col-md-6">
        <Points></Points>
      </div>
    </div>
    <StateCityPie></StateCityPie>
    <ReferralTable :filters="data_filters"></ReferralTable> -->
  </Layout>
</template>
  
  <script>
  import referralSummary from "./referral-summary.vue";
  import Layout from "../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Filters from './filters.vue';
  // import AnalyticsStatesCities from './pie-charts/analytics-states-cities.vue';
  // import Verified from "./data-progress.vue/Verified.vue";
  // import NotVerified from "./data-progress.vue/NotVerified.vue";
  // import TotalDoctors from "./TotalDoctors.vue"
  // import ReferralsBy from "./ReferralsBy.vue";
  // import Points from "./Points.vue";
  // import ReferralTable from "./ReferralTable.vue";
  // import StateCityPie from './pie-charts/StateCityPie.vue';
  
  export default {
    name: "main-page",
    page: {
      title: "Field MR Referral Summary",
      meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
      return {
        title: "Field MR",
        items: [
          {
            text: "Medisage",
          },
          {
            text: "Referral Summary",
            active: true,
          },
        ],
        telecallers: [],
        data_filters: [],
      };
    },
    methods: {
      getFilters(e) {
        this.data_filters = e;
      },
      async getTelecallers(){
        try {
          let res = await this.$http.get(appConfig.api_base_url + '/telecallers',{
            params : {
              "is_field_mr" : true
            }
          });
          if(res.data.telecallers){
            this.telecallers = res.data.telecallers;
          }
        }catch (e){
          console.log(e);
        }
      }
    },
    mounted() {
       this.getTelecallers();
    },
    components: {
    referralSummary,
    Layout,
    PageHeader,
    Filters,
    // AnalyticsStatesCities  ,
    // Verified,
    // NotVerified,
    // TotalDoctors,
    // ReferralsBy,
    // Points,
    // ReferralTable,
    // StateCityPie
},
  };
  </script>
  